
import Modal from '@/components/UI/Modal/index.vue';
import { defineComponent, PropType, ref } from 'vue';
import { ObjectWorker, TradeObjectPreview } from '@/types/ObjectOfTrade';
import api from '@/api';

export default defineComponent({
  name: 'TransferObjectModal',
  components: { Modal },
  props: {
    transferToInspector: {
      type: Object as PropType<ObjectWorker>,
    },
    selectedRows: {
      type: Object as PropType<TradeObjectPreview[]>,
    },
    isObjects: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'transferSuccess'],
  setup(props, { emit }) {
    const loading = ref(false);

    async function transferAction(): Promise<void> {
      if (props.transferToInspector && props.selectedRows?.length) {
        try {
          loading.value = true;
          if (props.isObjects) {
            await api.objectsOfTrade.assignWorkerToObject(
              props.transferToInspector?.id,
              { tradeobjects: props.selectedRows?.map((el) => el.id) },
            );
          } else {
            await api.taxPayers.assignWorkerToTaxPayer(
              props.transferToInspector?.id,
              props.selectedRows?.map((el) => el.id),
            );
          }
          emit('transferSuccess');
          emit('close');
        } catch (e) {
          console.error(e);
        } finally {
          loading.value = false;
        }
      }
    }

    return {
      transferAction,
      loading,
    };
  },
});
