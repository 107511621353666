import { reactive } from 'vue';
import api from '@/api';

export interface ActivityTypeOption {
  name: string,
  value: number | void,
  id: number,
}

export default function useActivityTypes() {
  const activityTypes = reactive({
    selected: {} as ActivityTypeOption,
    options: [] as ActivityTypeOption[],
    async fetch(choiceType: boolean | null | undefined) {
      try {
        let resp;
        if (choiceType === undefined || choiceType == null) {
          resp = null;
        } else if (choiceType) {
          resp = await api.activityType.findAll();
        } else {
          resp = await api.businessType.findAll();
        }
        activityTypes.options = [{ name: 'Не выбрано', id: 0, value: undefined }];

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        activityTypes.options.push(...resp.data.map((item) => ({
          name: `${item.value || ''}`,
          value: item.id || 0,
          id: item.id || 0,
        })));
      } catch (e) {
        console.log(e);
      }
    },
    selectById(id: number) {
      activityTypes.selected = activityTypes.options?.find((item) => item.id === id) || activityTypes.selected;
      return activityTypes.selected;
    },
  });

  return { activityTypes };
}
