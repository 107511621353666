
/* eslint-disable no-use-before-define */
import {
  defineComponent, reactive, ref, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import ContainerHeader from '@/components/ContainerHeader.vue';
import UiSwitcher from '@/components/UI/UiSwitcher.vue';
import { ObjectStatus } from '@/constants/objectStatuses';
import PageControls from '@/components/PageControls.vue';
import searchIcon from '@/assets/images/ic-search.svg';
import { TaxPayer, TradeObjectPreview } from '@/types/ObjectOfTrade';
import { StatusStat } from '@/types/Statistic';
import Container from '@/components/Container.vue';
import api from '@/api';
import ViewportObserver from '@/components/ViewportObserver.vue';
import usePagination from '@/modules/usePagination';
import { UgnsTnoWorker } from '@/types/UgnsTnoById';
import PageStats from '@/components/PageStats/index.vue';
import { badgeProps, parseDate } from '@/utils';
import TransferObjectModal from '@/components/Modals/TransferObjectModal.vue';
import ColorSelector from '@/components/UI/ColorSelector.vue';
import useActivityTypes from '@/modules/useActivityTypes';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ObjectId',
  components: {
    ColorSelector,
    TransferObjectModal,
    PageStats,
    Container,
    PageControls,
    ContainerHeader,
    ViewportObserver,
    UiSwitcher,
  },
  setup() {
       interface CurrentFilters extends Record<string, unknown> {
      status: ObjectStatus
    }
       const store = useStore();
       const route = useRoute();
       const selectedRows = ref<TradeObjectPreview[]>([]);
       const showTransferModal = ref(false);
       const inspectors = ref();
       const transferToInspector = ref<UgnsTnoWorker | undefined>();
       const selectedStatus = ref();
       const isObjects = ref(false);
       const cols = ref();
       const isRoleAdmin = store.state.auth.isAdmin;
       const changeStatusObjectTrade = {
         component: (row: TradeObjectPreview) => ({
           name: 'ChangeStatus',
           ...badgeProps(row.objectStatus),
           'onUpdate:status': async (objectStatus: string) => {
             await api.objectsOfTrade.saveById(row.id, { objectStatus });
             objects.pagination.reset();
             await objects.fetch();
             await worker.fetchInfoAndStats();
           },
         }),
       };
       const changeStatusTaxPayer = {
         component: (row: TaxPayer) => ({
           name: 'ChangeStatus',
           ...badgeProps(row.objectStatus),
           'onUpdate:status': async (objectStatus: string) => {
             await api.taxPayers.saveById(row.id, { objectStatus });
             objects.pagination.reset();
             await objects.fetch();
             await worker.fetchInfoAndStats();
           },
         }),
       };

       const { activityTypes } = useActivityTypes();
       activityTypes.fetch(true);

       async function getInspectors() {
         if (worker.info) {
           inspectors.value = (await api.ugnstno.getWorkersById(worker.info.userProfile.ugnsTno.id)).data.map((el) => ({
             ...el,
             name: `${el.userProfile.lastName} ${el.userProfile.firstName} ${el.userProfile.middleName}`,
           }));
         }
       }

       const worker = reactive({
         fetching: false,
         info: null as UgnsTnoWorker | null,
         stats: null as StatusStat | null,
         async fetch() {
           this.fetching = true;
           try {
             this.info = (await api.user.getWorkerById(+route.params.workerId)).data;
             await getInspectors();
           } catch (e) {
             console.log(e);
           } finally {
             this.fetching = false;
           }
         },
         async fetchStats() {
           this.stats = (await api.user.getUserStats(+route.params.workerId)).data;
         },
         async fetchInfoAndStats() {
           await Promise.all([this.fetch(), this.fetchStats()]);
         },
       });

       worker.fetchInfoAndStats();

       const objects = usePagination({
         filters: {
           address: '',
           nameorinn: '',
           activityId: '',
           status: '',
         },
         delayedFilters: ['address', 'nameorinn', 'activityId', 'status'],
         immediateFilters: ['status'],
         rows: [] as TradeObjectPreview[],
         async fetchContents() {
           const filters = objects.filters as CurrentFilters;
           const status = filters.status.value ? `${filters.status.value}` : null;
           const { address, nameorinn, activityId } = objects.filters as { address: string, nameorinn: string, activityId: string };
           const withDuplicates = store.state.auth.isAdmin;
           const params = {
             ...address && { address },
             ...activityId && { activityId },
             ...status && { status },
             ...nameorinn && { nameorinn },
             ...objects.pagination.params,
             inspector: +route.params.workerId,
             duplicate: withDuplicates,
           };
           if (isObjects.value) {
             return (await api.user.getUserTradeObjects(+route.params.workerId, params)).data;
           }
           return (await api.taxPayers.findAll(params)).data;
         },
       });

       function isObjectsWatchHandler(val: boolean): void {
         const { address, nameorinn } = objects.filters;
         objects.pagination.reset();
         if (address || nameorinn) {
           objects.filters.address = '';
           objects.filters.nameorinn = '';
         } else {
           objects.fetch();
         }
         if (val) {
           cols.value = [
             isRoleAdmin === true ? changeStatusObjectTrade : '',
             {
               label: 'Адрес',
               component: (row: TradeObjectPreview) => ({
                 name: 'router-link',
                 class: 'link',
                 value: row?.yandexAddressFull || row?.userInput || 'Не задан',
                 to: { name: 'registry-trade-objects-object-id', params: { objectId: row?.id } },
               }),
             },
             {
               label: 'тип помещения',
               display: (row: TradeObjectPreview) => row?.buildingType || 'Не задан',
               width: '30%',
             },
             {
               label: 'Кол-во НП',
               display: (row: TradeObjectPreview) => row.nalogPayerCount || 'Не задано',
             },
             {
               label: 'Дата создания',
               display: (row: TradeObjectPreview) => row.createDate?.split('T')[0] || 'Не задано',
             },
             {
               label: 'Дата передачи',
               display: (row: TradeObjectPreview) => row.delegateDate?.split('T')[0],
               defaultValue: 'Первое назначение',
             },
             {
               label: 'Инспектор до передачи',
               display: (row: TradeObjectPreview) => `${row.delegateFrom?.lastName || ''} ${row.delegateFrom?.firstName || ''} ${row.delegateFrom?.middleName || ''}`,
               defaultValue: 'Первое назначение',
             },
           ];
         } else {
           cols.value = [
             isRoleAdmin === true ? changeStatusTaxPayer : '',
             {
               label: 'ИНН',
               field: 'inn',
               defaultValue: 'Не задан',
             },
             {
               label: 'Торговое наименование',
               field: 'objectName',
               defaultValue: 'Не задано',
               width: '40%',
             },
             {
               label: 'Дата создания',
               field: 'createDate',
               display: (row: TaxPayer) => parseDate(row.createDate),
               defaultValue: 'Не задано',
             },
             {
               label: 'Дата передачи',
               display: (row: TaxPayer) => parseDate(row.delegateDate) || 'Первое назначение',
             },
             {
               label: 'Инспектор до передачи',
               display: (row: TaxPayer) => `${row.delegateFrom?.lastName || ''} ${row.delegateFrom?.firstName || ''} ${row.delegateFrom?.middleName || ''}`.trim() || 'Первое назначение',
             },
           ];
         }
       }

       watch(isObjects, isObjectsWatchHandler, { immediate: true });

       function onTransferSuccess() {
         objects.fetch();
         selectedRows.value = [];
       }

       return {
         worker,
         objects,
         searchIcon,
         cols,
         selectedRows,
         showTransferModal,
         transferToInspector,
         selectedStatus,
         inspectors,
         isObjects,
         onTransferSuccess,
         activityTypes,
       };
  },
});
