
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'UiSwitcher',
  props: {
    modelValue: { type: Boolean, default: false },
    onLabel: { type: String, default: '' },
    offLabel: { type: String, default: '' },
  },
  setup() {
    const isChecked = ref();

    return {
      isChecked,
    };
  },
});
